<template>
  <div class="faq--container">
    <h1>Памятка пользователя</h1>
    <p>Назначение сервиса – защита прав и здоровья участников образовательного процесса, обеспечение сохранности имущества учреждения, ознакомление с формой и содержанием образования<br>
    </p>
    <b><br>Пользователь обязан:</b>
    <ul>
      <li>
        - Не передавать ключи доступа посторонним! Использование сервиса, помимо работников образования, допустимо родителями (законными представителями) обучающихся
      </li>
      <li>
        - Не создавать копии любыми способами! В случае необходимости нужный фрагмент архива выдается по запросу, либо организуется коллективный просмотр в учреждении

      </li>
      <li>
        - Не распространять фото видео материалы, полученные в сервисе, в том числе в сети Интернет

      </li>
      <li>
        - Не допускать вмешательства в образовательный процесс на основании информации, полученной в сервисе! Не предпринимать попыток корректирования профессиональной деятельности педагогов!

      </li>
      <li>
        - Не использовать сервис для идентификации изображенных на видео людей без их письменного согласия!

      </li>
      <li>
        <br>В случае неоднократного нарушения правил, сервис оставляет за собой право на прекращение предоставления услуг без объяснения причин.<br>
        <br>Используйте возможности во благо, а не во вред!

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SettingsFaqView"
}
</script>

<style scoped lang="scss">
.faq--container{
  // компы

  width: 708px;
  height: 411px;
  outline: 0;
  border: 0;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  margin-top: 71px;

  &::-webkit-scrollbar{
    display: none;
  }


  @include media(1200){
    //маленькие компы
    width: 708px;
    height: 411px;
    outline: 0;
    border: 0;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    margin-top: 71px;
  }

  @include media(992){
    //планшеты
    width: 670px;
    height: 456px;
    outline: 0;
    border: 0;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    margin-top: 43px;
  }

  @include media(767){
    //телефоны
    width: 335px;
    height: 708px;
    outline: 0;
    border: 0;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    padding-bottom: 200px;
  }




  h1{
    font-family: CodeNext;
    font-weight: bold;
    font-size: 24px;
    color: #222222;
  }

  p{
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 5px;


    font-size: 14px;
    color: #333942;
    line-height: 21px;
  }

  ul {
    list-style-type: none;
    li {
      position: relative;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0;
      font-size: 14px;
      color: #333942;
      line-height: 21px;

      strong{
        font-family: Roboto, "sans-serif";
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #333942;
        letter-spacing: 0;
      }
    }
  }

  b{
    font-family: Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    font-size: 14px;
    color: #333942;
    line-height: 21px;
  }
}
</style>
